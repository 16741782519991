<template>
    <section>
      <div v-if="nivel === 1" class="md:flex gap-2">
        <div class="w-full lg:w-6/12">
          <div v-for="(nivel, i) of listadoServicios[0]" :key="i">
            <div class="p-2 flex items-center justify-between border-b-2 border-gray-600">
              <button @click="nivel.open = !nivel.open" class="flex gap-2 items-center text-gray-800">
                <div v-if="nivel.per.length && nivel.per.some(a => !a.creacion_edicion)">
                  <svg v-if="nivel.open" style="width:24px;height:24px" viewBox="0 0 24 24">
                      <path fill="gray" d="M7,10L12,15L17,10H7Z" />
                  </svg>
                  <svg v-else style="width:24px;height:24px" viewBox="0 0 24 24">
                      <path fill="gray" d="M10,17L15,12L10,7V17Z" />
                  </svg>
                </div>
                <p class="text-gray-600 font-bold">
                  {{ nivel.name }}
                </p>
              </button>
              <select
                v-model="nivel.autorizacion"
                @change="activarFamilia(nivel)"
                :class="nivel.autorizacion === 1 ? 'text-blue-600' : (nivel.autorizacion === 2 ? 'text-blue-300' : 'text-gray-400')"
                class="p-1 border-gray-400 border rounded-md">
                <option
                  v-for="(auto, k) in (nivel.per.length ? (nivel.per.some(a => a.creacion_edicion === true) ? permisosLimitados : listadoTiposPermisosConHijos) : listadoTiposPermisos)"
                  :key="k"
                  :value="auto.id"
                  class="font-medium text-gray-600"
                >
                <p>
                  {{ auto.name }}
                </p>
                </option>
              </select>
            </div>
            <div v-show="nivel.open && nivel.per.length">
              <div v-if="nivel.per.some(a => a.creacion_edicion === false)">
                <permisos class="ml-4" :nivel="2" :listadoServicios="nivel.per" />
              </div>
            </div>
          </div>
        </div>
        <div class="w-full lg:w-6/12">
          <div v-for="(nivel, i) of listadoServicios[1]" :key="i">
            <div class="p-2 flex items-center justify-between border-b-2 border-gray-600">
              <button @click="nivel.open = !nivel.open" class="flex gap-2 items-center text-gray-800">
                <div v-if="nivel.per.length && nivel.per.some(a => !a.creacion_edicion)">
                  <svg v-if="nivel.open" style="width:24px;height:24px" viewBox="0 0 24 24">
                      <path fill="gray" d="M7,10L12,15L17,10H7Z" />
                  </svg>
                  <svg v-else style="width:24px;height:24px" viewBox="0 0 24 24">
                      <path fill="gray" d="M10,17L15,12L10,7V17Z" />
                  </svg>
                </div>
                <p class="text-gray-600 font-bold">
                  {{ nivel.name }}
                </p>
              </button>
              <select
                v-model="nivel.autorizacion"
                @change="activarFamilia(nivel)"
                :class="nivel.autorizacion === 1 ? 'text-blue-600' : (nivel.autorizacion === 2 ? 'text-blue-300' : 'text-gray-400')"
                class="p-1 border-gray-400 border rounded-md">
                <option
                  v-for="(auto, k) in (nivel.per.length ? (nivel.per.some(a => a.creacion_edicion === true) ? permisosLimitados : listadoTiposPermisosConHijos) : listadoTiposPermisos)"
                  :key="k"
                  :value="auto.id"
                  class="font-medium text-gray-600"
                >
                <p>
                  {{ auto.name }}
                </p>
                </option>
              </select>
            </div>
            <div v-show="nivel.open && nivel.per.length">
              <div v-if="nivel.per.some(a => a.creacion_edicion === false)">
                <permisos class="ml-4" :nivel="2" :listadoServicios="nivel.per" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="md:flex gap-2">
        <div class="w-full">
          <div v-for="(nivel, i) of listadoServicios" :key="i">
            <div class="p-2 flex items-center justify-between border-b-2 border-gray-600">
              <button @click="nivel.open = !nivel.open" class="flex gap-2 items-center text-gray-800">
                <div v-if="nivel.per.length && nivel.per.some(a => !a.creacion_edicion)">
                  <svg v-if="nivel.open" style="width:24px;height:24px" viewBox="0 0 24 24">
                      <path fill="gray" d="M7,10L12,15L17,10H7Z" />
                  </svg>
                  <svg v-else style="width:24px;height:24px" viewBox="0 0 24 24">
                      <path fill="gray" d="M10,17L15,12L10,7V17Z" />
                  </svg>
                </div>
                <p class="text-gray-600 font-bold">
                  {{ nivel.name }}
                </p>
              </button>
              <select
                v-model="nivel.autorizacion"
                @change="activarFamilia(nivel)"
                :class="nivel.autorizacion === 1 ? 'text-blue-600' : (nivel.autorizacion === 2 ? 'text-blue-300' : 'text-gray-400')"
                class="p-1 border-gray-400 border rounded-md">
                <option
                  v-for="(auto, k) in (nivel.per.length ? (nivel.per.some(a => a.creacion_edicion === true) ? permisosLimitados : listadoTiposPermisosConHijos) : listadoTiposPermisos)"
                  :key="k"
                  :value="auto.id"
                  class="font-medium text-gray-600"
                >
                <p>
                  {{ auto.name }}
                </p>
                </option>
              </select>
            </div>
            <div v-show="nivel.open && nivel.per.length">
              <div v-if="nivel.per.some(a => a.creacion_edicion === false)">
                <permisos class="ml-4" :nivel="2" :listadoServicios="nivel.per" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </template>
  <script>
  import { ref, computed } from 'vue'
  import permisosStore from '../../../../../../stores/permisos.store'
  import permisos from './permisos.vue'

  export default {
    name: 'permisos',
    props: {
      listadoServicios: {
        type: Array,
        default: () => ([])
      },
      nivel: {
        type: Number,
        default: () => (0)
      }
    },
    components: {
      permisos
    },
    setup (props) {
      // Manual Data
      const listadoTiposPermisosConHijos = ref([
        {
          name: 'Autorización total',
          id: 1,
          class: 'bg-gray-100 text-blue-600'
        },
        {
          name: 'Varias autorizaciones',
          id: 2,
          class: 'bg-gray-100 text-gray-800'
        },
        {
          name: 'Falta autorización',
          id: 3,
          class: 'bg-gray-100 text-gray-400'
        }
      ])
      const listadoTiposPermisos = ref([
        {
          name: 'Autorización total',
          id: 1,
          class: 'bg-gray-100 text-blue-600'
        },
        {
          name: 'Falta autorización',
          id: 3,
          class: 'bg-gray-100 text-gray-400'
        }
      ])
      const permisosLimitados = ref([
        {
          name: 'Autorización total',
          id: 1,
          class: 'bg-gray-100 text-blue-600'
        },
        {
          name: 'Solo lectura',
          id: 2,
          class: 'bg-gray-100 text-gray-800'
        },
        {
          name: 'Falta autorización',
          id: 3,
          class: 'bg-gray-100 text-gray-400'
        }
      ])
      // Computed
      const listadoServiciosCompletos = computed(() => permisosStore.getters._listadoServicios)
      const grupoId = computed(() => permisosStore.getters._grupoSelecionado)
      // Methods
      const activarHijos = (perm) => {
        perm.per.forEach(a => {
          if (perm.autorizacion === 1 || perm.autorizacion === 3) {
            a.autorizacion = perm.autorizacion
          } else {
            a.autorizacion = 3
          }
        })
      }
      const activarPadres = (perm) => {
        const active = (data) => {
          if (data.per.some(b => b.autorizacion === 1)) {
            if (data.per.some(b => b.autorizacion === 2 || b.autorizacion === 3)) {
              return 2
            } else {
              return 1
            }
          } else if (data.per.some(b => b.autorizacion === 2)) {
            return 2
          } else {
            return 3
          }
        }
        const recorrer = (padre) => {
          if (padre.per.length) {
            if (padre.per.some(a => a.action === perm.action)) {
              padre.autorizacion = active(padre)
            } else {
              for (const hijo of padre.per) {
                recorrer(hijo)
              }
              if (padre.per.some(b => b.creacion_edicion === true) && padre.autorizacion === 2) {} else {
                padre.autorizacion = active(padre)
              }
            }
          }
        }
        for (const i of listadoServiciosCompletos.value) {
          for (const j of i) {
            recorrer(j)
          }
        }
      }
      const activarFamilia = (permiso) => {
        if (permiso.per.length && permiso.per.some(x => x.creacion_edicion === false) && permiso.autorizacion === 2) {
          permiso.autorizacion = 3
        } else {
          const recorrer = (padre) => {
            if (padre.per.length) {
              activarHijos(padre)
              for (const hijo of padre.per) {
                recorrer(hijo)
              }
            }
          }
          recorrer(permiso)
          activarPadres(permiso)
          permisosGuardar()
        }
      }
      const permisosGuardar = () => {
        const array = []
        const saveData = (data) => {
          array.push({
            permission_action: data.action,
            state: true,
            type: 'SecurityGroups',
            base: grupoId.value
          })
        }
        const recorrer = (padre) => {
          if (padre.autorizacion === 1 || padre.autorizacion === 2) {
            saveData(padre)
            if (padre.per.length) {
              for (const hijo of padre.per) {
                recorrer(hijo)
              }
            }
          }
        }
        for (const i of listadoServiciosCompletos.value) {
          for (const j of i) {
            recorrer(j)
          }
        }
        permisosStore.commit('setPermisosGuardar', array)
      }

      return {
        listadoTiposPermisosConHijos,
        listadoTiposPermisos,
        permisosLimitados,
        activarFamilia,
        activarPadres,
        activarHijos
      }
    }
  }
  </script>

  <style lang="scss" scoped>
    ::-webkit-scrollbar {
      width: 0.2rem;
    }
    ::-webkit-scrollbar-track {
      background: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background: rgb(248, 248, 248);
      border-radius: 9999px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: rgb(207, 207, 207);
    }
    .selectButtonPermissions {
      ::v-deep(.p-checkbox .p-checkbox-box) {
        border-radius: 50%;
      }
      ::v-deep(.p-selectbutton .p-button.p-highlight){
        background: white;
        color: #1C3FAA;
        width: 100%;
        font-weight: 500;
        font-size: 1rem;
        border: 1px solid rgb(28, 63, 170);
        margin-top: 0.5rem;
     }
     ::v-deep(.p-selectbutton .p-button.p-highlight:hover){
        background: white;
        color: #1C3FAA;
        width: 100%;
        font-weight: 500;
        font-size: 1rem;
        border: 1px solid rgb(28, 63, 170);
        margin-top: 0.5rem;
     }
     ::v-deep(.p-selectbutton .p-button){
        background: #F6F6F6;
        color: #7F7E81;
        width: 100%;
        font-size: 1rem;
        margin-top: 0.5rem;
        border: transparent;
      }
     ::v-deep(.p-selectbutton .p-button:hover){
        background: #F6F6F6;
        color: #7F7E81;
        width: 100%;
        font-size: 1rem;
        margin-top: 0.5rem;
        border: transparent;
     }
     ::v-deep(.p-dropdown .p-dropdown-label){
        font-size: 0.4rem !important;
        padding: 0.2rem !important;
     }
    }
  </style>
